import * as QueryKeys from "data";

import { Box, Card } from "@mui/material";
import { useIdentity } from "contexts/identity-context";

import CustomTable from "components/shared/table";
import {
  fetchAdminCustomDatasetsSearch,
  fetchAdminCustomDefinitions,
} from "data/queries";
import { useEffect, useState } from "react";
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import * as localStorageKeys from "data/local-storage-keys";
import { CustomDatasetFilterType, CustomDatasetList } from "data/models";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { customColumns, datasetsToRows } from "./columns-rows";

import { LoadingButton } from "@mui/lab";
import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { manageCustomDatasetsStatus } from "data/mutations";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { AdminCustomDatasetFilters } from "./admin-custom-datasets-filters";

export const AdminCustomDatasetsSearch = () => {
  const [identityState] = useIdentity();

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchAdminCustomDatasets
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  let initialQueryFilterState: CustomDatasetFilterType = {
    name: [],
    query: "",
    product: [],
    release: "",
    latestRelease: false,
    customerName: [],
    notPublished: false,
  };

  const [queryFilterState, setQueryFilterState] =
    useState<CustomDatasetFilterType>(initialQueryFilterState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const searchQueryKey = [
    QueryKeys.customDatasetsSearchQuery,
    queryFilterState,
  ];
  const customDatasetsSearchQuery: UseQueryResult<CustomDatasetList> = useQuery(
    searchQueryKey,
    () => fetchAdminCustomDatasetsSearch(queryFilterState, identityState)
  );

  const queryClient = useQueryClient();
  const customDatasetsManageMutation = useMutation(
    (values: any) => manageCustomDatasetsStatus(values, identityState),
    {
      onSuccess: async (response) => {
        await new Promise((r) => setTimeout(r, 2000)); // Wait a bit before resetting the cache

        queryClient.resetQueries({
          queryKey: searchQueryKey,
          exact: true,
        });
      },
    }
  );

  const definitions = useQuery([QueryKeys.customDatasetDefinitions], () =>
    fetchAdminCustomDefinitions(identityState)
  );

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch: savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      initialQueryFilterState,
      definitions: definitions,
    });
  }, []);

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    return "";
  };

  const customColumns_ = customColumns(
    identityState,
    searchQueryKey,
    queryClient
  );

  const isSuccess = customDatasetsSearchQuery.isSuccess;
  const isLoading = customDatasetsSearchQuery.isLoading;
  const rowsData = customDatasetsSearchQuery.data
    ? datasetsToRows(customDatasetsSearchQuery.data)
    : [];

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[customDatasetsSearchQuery, definitions]}
          mutations={[customDatasetsManageMutation]}
        />

        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FilterComponent
            queryFilterState={queryFilterState}
            setQueryFilterState={setQueryFilterState}
            initialQueryFilterState={initialQueryFilterState}
            renderFilter={AdminCustomDatasetFilters}
            definitions={definitions}
            localStorageKey={localStorageKeys.searchAdminCustomDatasets}
          />
        </Card>
        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "internal",
                })
              }
            >
              Unpublish
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "published",
                })
              }
            >
              Publish
            </LoadingButton>
          </Box>
        </Card>

        <Card>
          <CustomTable
            getCustomRowClass={getCustomRowLicence}
            rows={isSuccess ? rowsData : []}
            cols={isLoading ? [] : customColumns_}
            isLoading={isLoading}
            overlayText={
              "We couldn't find any datasets that match your filter criteria."
            }
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectedIds(ids as string[]);
            }}
          />
        </Card>
      </div>
    </Box>
  );
};
