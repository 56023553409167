import { useIdentity } from "contexts/identity-context";
import { useHistory } from "react-router-dom";
import { HistoryState } from "../account-subscription/types";

import { getActiveOrganisation } from "contexts/identity-context";

// reactQuery
import dayjs from "dayjs";
import { useState } from "react";

import { unixDurationToDateRange } from "components/usage/utils-usage";
import { useFetchAdminApiKeys, useFetchAdminUsageGraph } from "./fetch-hooks";

import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import DateRangePickerComponent from "components/usage/components/data-range-picker/date-range-picker";
import {
  UsageCreditByTimeTable,
  UsageTable,
} from "components/usage/components/usage-table/usage-tables";

import UsageCard from "components/usage/components/usage-card";
import { UsageGraph } from "components/usage/components/usage-graph/ UsageGraph";
import { UsageSkeleton } from "components/usage/components/usage-graph/UsageSkeleton";

export const AdminUsageAccount = () => {
  const history = useHistory();
  const [identityState] = useIdentity();

  const { accountId, accountName } = history.location.state as HistoryState;

  const isUser = !getActiveOrganisation(identityState);

  const [startTime, setStartTime] = useState(
    dayjs().subtract(29, "day").startOf("day").unix() * 1000
  );

  const [endTime, setEndTime] = useState(dayjs().endOf("day").unix() * 1000);

  const [label, setLabel] = useState(
    unixDurationToDateRange(startTime, endTime).toString()
  );

  const usageQuery = useFetchAdminUsageGraph(
    isUser,
    identityState,
    accountId,
    startTime,
    endTime,
    true
  );

  const apiKeysQuery = useFetchAdminApiKeys(identityState, accountId);

  const isLoading = usageQuery.isLoading || apiKeysQuery.isLoading;
  const isError = usageQuery.isError || apiKeysQuery.isError;
  const isSuccess = usageQuery.isSuccess && apiKeysQuery.isSuccess;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[apiKeysQuery, usageQuery]}
          mutations={[]}
        />

        {/* Div DatePicker and Credits*/}
        <div
          style={{
            display: "flex",
            height: "90px",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <DateRangePickerComponent
            isFreemium={false}
            label={label}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setLabel={setLabel}
          />

          {/* USAGE CARD */}
          <UsageCard
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            totalCredits={usageQuery.data?.appCreditsData.total_credits}
          />
        </div>
        {/* Div Date Range and Graph Usage */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "24px",
            height: "500px",
            position: "relative",
          }}
        >
          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageGraph
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
              />
            )}
          </div>

          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageCreditByTimeTable
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
              />
            )}
          </div>

          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageTable
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
                apiKeysQuery={apiKeysQuery}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
