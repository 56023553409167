import * as QueryKeys from "data";

import { Box, Button, Card } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as Routes from "routes";

import { makeStyles } from "@mui/styles";
import CustomTable from "components/shared/table";
import { fetchCustomDatasetsAdmin, fetchCustomDefinitions } from "data/queries";
import { useEffect, useState } from "react";
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { generatePath } from "react-router-dom";
import { resolveIdentityId } from "utils/identity";

import * as localStorageKeys from "data/local-storage-keys";
import { CustomDatasetFilterType, CustomDatasetList } from "data/models";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { customColumns, datasetsToRows } from "./columns-rows";

import { LoadingButton } from "@mui/lab";
import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { manageCustomDatasetsStatus } from "data/mutations";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { useHistory } from "react-router-dom";
import { HistoryState } from "../account/account-subscription/types";
import { CustomDatasetFilters } from "./filters";

export const ViewCustomDatasets = () => {
  const history = useHistory();
  const {
    accountId,
    accountName,
    apigeeDeveloperId,
    salesforceId,
    email,
    hasCustomDataOp,
  } = history.location.state as HistoryState;

  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchCustomDatasets
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  let initialQueryFilterState: CustomDatasetFilterType = {
    name: [],
    query: "",
    product: [],
    release: "",
    customerName: [],
    latestRelease: false,
    notPublished: false,
  };

  const [queryFilterState, setQueryFilterState] =
    useState<CustomDatasetFilterType>(initialQueryFilterState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const searchQueryKey = [
    QueryKeys.customDatasetsSearchQuery,
    apigeeDeveloperId,
    queryFilterState,
  ];
  const customDatasetsSearchQuery: UseQueryResult<CustomDatasetList> = useQuery(
    searchQueryKey,
    () =>
      fetchCustomDatasetsAdmin(
        queryFilterState,
        apigeeDeveloperId as string,
        identityState
      )
  );

  const queryClient = useQueryClient();
  const customDatasetsManageMutation = useMutation(
    (values: any) => manageCustomDatasetsStatus(values, identityState),
    {
      onSuccess: async (response) => {
        await new Promise((r) => setTimeout(r, 2000)); // Wait a bit before resetting the cache

        queryClient.resetQueries({
          queryKey: searchQueryKey,
          exact: true,
        });
      },
    }
  );

  const definitions = useQuery([QueryKeys.customDatasetDefinitions], () =>
    fetchCustomDefinitions(identityState)
  );

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch: savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      initialQueryFilterState,
      definitions: definitions,
    });
  }, []);

  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    return "";
  };

  const customColumns_ = customColumns(
    identityState,
    searchQueryKey,
    queryClient
  );

  const isSuccess = customDatasetsSearchQuery.isSuccess;
  const isLoading = customDatasetsSearchQuery.isLoading;
  const rowsData = customDatasetsSearchQuery.data
    ? datasetsToRows(customDatasetsSearchQuery.data)
    : [];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          top: "-70px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            history.push(
              generatePath(Routes.adminCreateCustomerCustomDatasets, {
                accountId: accountId,
              }),
              {
                accountId: accountId,
                accountName: accountName,
                apigeeDeveloperId: apigeeDeveloperId,
                salesforceId: salesforceId,
                email: email,
              }
            )
          }
        >
          + New Release
        </Button>
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[customDatasetsSearchQuery, definitions]}
          mutations={[customDatasetsManageMutation]}
        />

        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FilterComponent
            queryFilterState={queryFilterState}
            setQueryFilterState={setQueryFilterState}
            initialQueryFilterState={initialQueryFilterState}
            renderFilter={CustomDatasetFilters}
            definitions={definitions}
            localStorageKey={localStorageKeys.searchCustomDatasets}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "space-between",
            }}
          >
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "internal",
                })
              }
            >
              Unpublish
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "published",
                })
              }
            >
              Publish
            </LoadingButton>
          </Box>
        </Card>

        <Card>
          <CustomTable
            getCustomRowClass={getCustomRowLicence}
            rows={isSuccess ? rowsData : []}
            cols={isLoading ? [] : customColumns_}
            isLoading={isLoading}
            overlayText={
              "We couldn't find any datasets that match your filter criteria."
            }
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectedIds(ids as string[]);
            }}
          />
        </Card>
      </div>
    </Box>
  );
};
