import { Alert, Card } from "@mui/material";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomTable from "components/shared/table";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { AppTotal } from "contexts/models";
import { resolveAppName } from "../../utils-usage";

import { cols } from "./colums";
import { modifyColumns } from "./utils";

interface UsageTableProps {
  startTime: number;
  endTime: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  usageQuery?: any;
  apiKeysQuery?: any;
}

export const UsageTable = (props: UsageTableProps) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);

  const usageQuery = props.usageQuery;
  const apiKeysQuery = props.apiKeysQuery;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: "5%",
      }}
    >
      <ReactQueryErrorWrapper
        queries={[usageQuery, apiKeysQuery]}
        mutations={[]}
      />

      {props.isSuccess && (
        <>
          <Card>
            <CustomTable
              rows={buildRowData(apiKeysQuery, usageQuery)}
              cols={cols}
              isLoading={props.isLoading}
              overlayText={"No usage data"}
              toolbar={true}
              initialState={{ pinnedColumns: { left: ["keyName"] } }}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export const UsageCreditByTimeTable = (props: UsageTableProps) => {
  const usageQuery = props.usageQuery;

  function getRowId(row: any) {
    return row.Date;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: "5%",
      }}
    >
      {props.isError && (
        <Alert severity="error">
          An error occurred while fetching the usage data.
        </Alert>
      )}

      {props.isSuccess && (
        <>
          <Card>
            <CustomTable
              rows={usageQuery.data?.appCreditsData.rows}
              cols={modifyColumns(usageQuery.data?.appCreditsData.columns)}
              isLoading={props.isLoading}
              getRowId={getRowId}
              columnGroupingModel={usageQuery.data?.appCreditsData.group}
              overlayText={"No usage data"}
              toolbar={true}
              initialState={{ pinnedColumns: { left: ["keyName"] } }}
            />
          </Card>
        </>
      )}
    </div>
  );
};

const totalOrZero = (v: AppTotal | undefined) => {
  if (v !== undefined) {
    return Number(v.totalCredits.toFixed(1));
  }
  return 0;
};

const buildRowData = (apiKeysQuery: any, usageQuery: any) => {
  let keyNameMapping: Record<string, string> = {};
  apiKeysQuery.data.forEach(function (key: any) {
    const keyName: string = key.name;
    const keyDisplayName: string = key.displayName ? key.displayName : key.name;
    keyNameMapping[keyName] = keyDisplayName;
  });

  const rows: any = [];

  usageQuery.data?.appProductTotals.forEach((app: any, index: any) => {
    if (app.totalUsage.length === 0 && keyNameMapping[app.name] === undefined) {
      return;
    }
    let keyName;

    rows.push({
      id: app.name,
      keyName: resolveAppName(app.name, keyNameMapping),
      predictive: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("predictive"))
      ),
      addresses: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("addresses"))
      ),
      landparcels: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("land-parcels"))
      ),
      buildings: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("buildings"))
      ),
      batches: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("batches"))
      ),
      adminbounds: totalOrZero(
        app.totalUsage.find((x: any) =>
          x.product.includes("administrative-boundaries")
        )
      ),
      maps: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("maps"))
      ),
      esri: totalOrZero(
        app.totalUsage.find((x: any) =>
          x.product.includes("esri-compatible-locator")
        )
      ),
      clip: totalOrZero(
        app.totalUsage.find((x: any) => x.product.includes("clip"))
      ),
    });
  });

  return rows;
};
