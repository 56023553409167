import { Box, Button } from "@mui/material";
import * as QueryKeys from "data";

import { ApiKeyTable } from "components/api-keys/api-keys-table";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import { fetchAdminUserApiKeys } from "data/queries";
import { useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import * as Routes from "routes";
import { HistoryState } from "../account-subscription/types";

const AdminApiKeys = () => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const queryClient = useQueryClient();

  const { accountId, accountName } = history.location.state as HistoryState;

  const adminUserApiKeysQuery = useQuery(
    [QueryKeys.adminUserApiKeys, accountId],
    () => fetchAdminUserApiKeys(accountId, identityState)
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          top: "-70px",
        }}
      >
        <Button
          onClick={() => {
            history.push(
              generatePath(Routes.adminCreateApiKey, { accountId: accountId }),
              { accountId: accountId, accountName: accountName }
            );
          }}
          variant="contained"
        >
          Create API Key
        </Button>
      </Box>

      <Box
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[adminUserApiKeysQuery]}
          mutations={[]}
        />

        <ApiKeyTable
          data={adminUserApiKeysQuery.data}
          isLoading={adminUserApiKeysQuery.isLoading}
          apiKeyDetailsRoute={Routes.adminEditApiKey}
          accountId={accountId}
          accountName={accountName}
        />
      </Box>
    </>
  );
};

export { AdminApiKeys };
