import { getActiveOrganisation, useIdentity } from "contexts/identity-context";

// reactQuery
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import dayjs from "dayjs";
import { useState } from "react";
import DateRangePickerComponent from "./components/data-range-picker/date-range-picker";
import { EditOverageCard } from "./components/edit-overage-card";
import UsageCard from "./components/usage-card";
import { UsageGraph } from "./components/usage-graph/ UsageGraph";
import { UsageSkeleton } from "./components/usage-graph/UsageSkeleton";
import {
  UsageCreditByTimeTable,
  UsageTable,
} from "./components/usage-table/usage-tables";
import {
  useFetchApiKeys,
  useFetchSubscription,
  useFetchUsageGraph,
} from "./fetch-hooks";
import { unixDurationToDateRange } from "./utils-usage";

export const Usage = () => {
  // useQuery
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);

  const [startTime, setStartTime] = useState(
    dayjs().subtract(29, "day").unix() * 1000
  );
  const [endTime, setEndTime] = useState(dayjs().endOf("day").unix() * 1000);
  const [label, setLabel] = useState(
    unixDurationToDateRange(startTime, endTime).toString()
  );

  const subscriptionQuery = useFetchSubscription(isUser, identityState);
  const apiKeysQuery = useFetchApiKeys(isUser, identityState);

  const usageQuery = useFetchUsageGraph(
    isUser,
    identityState,
    startTime,
    endTime,
    !!subscriptionQuery.data
  );

  const isFreemium: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.name === "Freemium";

  const isLoading =
    subscriptionQuery.isLoading ||
    usageQuery.isLoading ||
    apiKeysQuery.isLoading;
  const isError =
    subscriptionQuery.isError || usageQuery.isError || apiKeysQuery.isError;
  const isSuccess =
    subscriptionQuery.isSuccess &&
    usageQuery.isSuccess &&
    apiKeysQuery.isSuccess;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[subscriptionQuery, usageQuery]}
          mutations={[]}
        />

        {/* Div Total Credits and Overage */}
        <div style={{ display: "flex", height: "90px", gap: "20px" }}>
          <DateRangePickerComponent
            isFreemium={isFreemium}
            label={label}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            setLabel={setLabel}
          />

          {/* USAGE CARD */}
          <UsageCard
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            totalCredits={usageQuery.data?.appCreditsData.total_credits}
          />

          <EditOverageCard isOrg={!isUser} />
        </div>
        {/* Div Date Range and Graph Usage */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "24px",
            height: "500px",
            position: "relative",
          }}
        >
          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageGraph
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
              />
            )}
          </div>
          <div></div>

          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageCreditByTimeTable
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
              />
            )}
          </div>

          <div>
            <UsageSkeleton isLoading={isLoading} />
            {isSuccess && (
              <UsageTable
                startTime={startTime}
                endTime={endTime}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                usageQuery={usageQuery}
                apiKeysQuery={apiKeysQuery}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
