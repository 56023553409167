import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Skeleton } from "@mui/material";
import Ajv from "ajv";
import { Basemap } from "components/basemap/basemap";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchAuthJWT } from "data/queries";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  MapLayerMouseEvent,
  MapRef,
  MapboxGeoJSONFeature,
  Marker,
  MarkerEvent,
  PointLike,
} from "react-map-gl";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { AddressSearch } from "./address-search";
import { AttributesCard } from "./attributes/attributes-card";
import { ClipMenu } from "./clip/clip-menu";
import { useLayerManager } from "./layer-manager";
import { ExplorerDisplayLayers } from "./layers/explorer-display-layers";
import { LayersCard } from "./layers/layers-card";
import { layerConfigurationArraySchema } from "./layers/models";
import "./popup.css";
import { ZoomControl } from "./zoom-control";

export const getRequestTransformer = (token: string) => {
  return (url: string, resourceType: string) => {
    if (
      (resourceType === "Source" || resourceType === "Tile") &&
      url.startsWith(`${import.meta.env.VITE_API_BASE_URL}`)
    ) {
      const params = new URLSearchParams({
        key: token,
      });
      return {
        url: `${url.replace(
          "/v1/maps/",
          "/v1/explorer/"
        )}?${params.toString()}`,
      };
    }
  };
};

const deduplicateFeaturesOnKey = (features: MapboxGeoJSONFeature[]) => {
  const unique: Record<string, MapboxGeoJSONFeature> = {};
  features.forEach((f) => {
    unique[extractPrimaryKeyFromObj(f.properties as object)] = f;
  });
  return Object.values(unique);
};

export const extractPrimaryKeyFromObj = (obj: object): string => {
  const record = obj as Record<string, string>;
  return record[guessFeaturePrimaryKey(Object.keys(obj)) ?? ""] ?? "";
};

export const guessFeaturePrimaryKey = (keys: string[]) => {
  const sortedByLength = keys.sort((a, b) => a.length - b.length);

  const guess = sortedByLength.find(
    (x) => (x.includes("pid") || x.includes("PID")) && !x.includes("polygon")
  );
  return guess;
};

export const ExplorerPage = () => {
  const [userState] = useIdentity();

  const mapRef = useRef<MapRef>();
  const initialZoom: number = 7;
  const addressZoom: number = 15;

  const [coordinates, setCoordinates] = useState({
    latitude: -35.32186,
    longitude: 149.14683,
  });
  const [showAttributes, setShowAttributes] = useState(false);
  const [showMarker, setShowMarker] = useState(false);
  const [dynamicZoom, setDynamicZoom] = useState(initialZoom);
  const [combinedAttributes, setCombinedAttributes] = useState({
    selectedAttribute: "",
    attributes: {},
  });

  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const urlLocationQuery = useLocation().search;
  const params = new URLSearchParams(urlLocationQuery);
  const clipId = params.get("clip_id") ?? undefined;

  /**
   * returns 
   * a list of maps of a map
   * 
   * {
      "cadastre__cadastre": [{
          "cadastre_polygon_pid": "cp08e7db9719345",
          "cadastre_pid": "cad5881e004a24f",
          "area": 1422796339.56,
          "parcel_type": "Lot",
          "base_parcel": "Yes"
      },
      {
          "cadastre_polygon_pid": "cp08e7db9719345",
          "cadastre_pid": "cad5881e004a24f",
          "area": 1422796339.56,
          "parcel_type": "Lot",
          "base_parcel": "Yes"
      }],
      "state__state": [{
          "state_polygon_pid": "12627",
          "state_pid": "1",
          "state_name": "NEW SOUTH WALES",
          "state_abbreviated": "NSW"
      }],
      "local_government_areas__local_government_areas": [{
          "lga_polygon_pid": "lgpg2iuLQh8MvVE",
          "lga_pid": "lga4ad25c8d4bff",
          "lga_name": "Snowy Monaro Regional Council",
          "area": 15163.000515576548
      }]
    }
   */

  const layerManager = useLayerManager();

  const mapAttributes = (
    features: MapboxGeoJSONFeature[]
  ): { [key: string]: { [key: string]: string }[] } => {
    return features.reduce(
      (prev: { [key: string]: { [key: string]: string }[] }, current) => {
        const properties: { [key: string]: string }[] = [];
        if (prev[current.layer.id]) properties.push(...prev[current.layer.id]);

        properties.push(current.properties as { [key: string]: string });
        return {
          ...prev,
          [current.layer.id]: properties,
        };
      },
      {}
    );
  };

  const onClick = (event: MapLayerMouseEvent) => {
    if (clipExpanded) {
      return;
    }
    const bbox = [
      [event.point.x - 5, event.point.y - 5],
      [event.point.x + 5, event.point.y + 5],
    ];
    // Find features intersecting the bounding box.
    const selectedFeatures = mapRef.current?.queryRenderedFeatures(
      bbox as [PointLike, PointLike],
      {
        layers: [...layerManager.selectedLayers.map((x) => x.id)],
      }
    );

    if (!selectedFeatures?.length) {
      return;
    }

    const collapsedSelectedFeatures =
      deduplicateFeaturesOnKey(selectedFeatures);
    const feature = collapsedSelectedFeatures[0];

    setCoordinates({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });

    const newCombinedAttributes = {
      attributes: mapAttributes(collapsedSelectedFeatures),
      selectedAttribute: feature.layer.id,
    };
    setCombinedAttributes(newCombinedAttributes);
    layerManager.setPrimaryHighlightFilter(feature.layer.id, [
      extractPrimaryKeyFromObj(feature.properties as object),
    ]);

    setShowAttributes(true);
    setShowMarker(true);

    const layers: Record<string, string[]> = {};
    collapsedSelectedFeatures.forEach((feature) => {
      if (layers[feature.layer.id]) {
        layers[feature.layer.id].push(
          extractPrimaryKeyFromObj(feature.properties as object)
        );
      } else {
        layers[feature.layer.id] = [
          extractPrimaryKeyFromObj(feature.properties as object),
        ];
      }
    });
    layerManager.setSecondaryHighlightFilter(layers);
  };

  const resetCompass = () => {
    if (mapRef.current === undefined) return;
    mapRef.current.easeTo({
      bearing: 0,
      pitch: 0,
    });
  };

  const zoomToLayerFn = () => {
    // bounding box of Australia
    const [minLng, minLat, maxLng, maxLat] = [96, -45, 168, -8];
    if (mapRef.current === undefined) return;
    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 0, duration: 1000 }
    );
  };

  const onSelectAddress = useCallback(
    ({
      longitude,
      latitude,
      geocoder_attributes,
    }: {
      longitude: number;
      latitude: number;
      geocoder_attributes: { [key: string]: string };
    }) => {
      setCoordinates({
        latitude: latitude,
        longitude: longitude,
      });
      geocoder_attributes["longitude"] = longitude.toString();
      geocoder_attributes["latitude"] = latitude.toString();

      setCombinedAttributes({
        selectedAttribute: "geocoder__geocoder",
        attributes: { geocoder__geocoder: [geocoder_attributes] },
      });
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        zoom: addressZoom,
      });
      setDynamicZoom(addressZoom);
      setShowMarker(true);
      setShowAttributes(true);
    },
    []
  );

  const handleZoom = (zoom: number): void => {
    setDynamicZoom(zoom);
    mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
  };

  const handleClearAddress = (): void => {
    setShowMarker(false);
    setShowAttributes(false);
  };

  const handleAttributesCardClose = (): void => {
    setShowMarker(false);
    setShowAttributes(false);
  };

  const onMarkerClick = (e: MarkerEvent): void => {
    setShowAttributes(!showAttributes);
  };

  const onAttributesInfoClick = (): void => {
    setShowMarker(true);
  };

  useEffect(() => {
    const storedArray = sessionStorage.getItem("selectedExplorerLayers");
    if (storedArray) {
      const selectedLayers = JSON.parse(storedArray);
      const ajv = new Ajv();
      // Compile the schema
      const validate = ajv.compile(layerConfigurationArraySchema);
      // Validate the JSON object against the schema
      const valid = validate(selectedLayers);

      if (!valid) {
        sessionStorage.removeItem("selectedExplorerLayers");
      }
    }
  }, []);

  const [clipExpanded, setClipExpanded] = useState<boolean>(false);

  const handleClipExpanded = (expanded: boolean) => {
    if (expanded) {
      setCombinedAttributes({ selectedAttribute: "", attributes: {} });
      setShowAttributes(false);
      setShowMarker(false);
      layerManager.setPrimaryHighlightFilter("", []);
      layerManager.setSecondaryHighlightFilter({});
    }

    setClipExpanded(expanded);
  };

  return (
    <>
      {layerManager.isLoading && (
        <Skeleton
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        ></Skeleton>
      )}
      {layerManager.isSuccess && (
        <Basemap
          // @ts-ignore
          mapRef={mapRef}
          attributionControl={true}
          latitude={coordinates.latitude}
          longitude={coordinates.longitude}
          zoom={initialZoom}
          onZoom={(zoom) => setDynamicZoom(zoom)}
          onClick={onClick}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
          interactiveLayerIds={layerManager.selectedLayers.map((l) =>
            layerManager.generateLayerId(l, "base")
          )}
          transformRequest={getRequestTransformer(
            jwt.isSuccess ? jwt.data : ""
          )}
        >
          {showMarker && (
            <Marker
              key={"selected-address"}
              longitude={coordinates.longitude}
              latitude={coordinates.latitude}
              // @ts-ignore
              onClick={onMarkerClick}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <LocationOnIcon fontSize="large" color="error" />
            </Marker>
          )}
          <Box
            sx={{
              left: 0,
              top: 40,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <ClipMenu
              setClipOpen={(v: boolean) => handleClipExpanded(v)}
              // @ts-ignore
              mapRef={mapRef}
              initialClipId={clipId}
            ></ClipMenu>
          </Box>
          <Box
            sx={{
              right: 32,
              top: 32,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              justifyContent: "flex-end",
            }}
          >
            <AddressSearch
              onSelectAddress={onSelectAddress}
              onClearAddress={handleClearAddress}
            />
            <ZoomControl
              resetCompass={resetCompass}
              dynamicZoom={dynamicZoom}
              onZoom={handleZoom}
            />
            <LayersCard
              layerManager={layerManager}
              zoomToLayerFn={zoomToLayerFn}
            />
            {combinedAttributes.attributes && (
              <AttributesCard
                show={showAttributes}
                attributes={combinedAttributes.attributes}
                onClose={handleAttributesCardClose}
                selectedAttribute={combinedAttributes.selectedAttribute}
                onSelectFeature={(featureId) => {
                  layerManager.setPrimaryHighlightFilter(
                    combinedAttributes.selectedAttribute,
                    [featureId]
                  );
                }}
                onInfoClick={onAttributesInfoClick}
                onSelectAttribute={(key) => {
                  setCombinedAttributes((prevState) => {
                    return {
                      ...prevState,
                      selectedAttribute: key,
                    };
                  });
                  const layersObj = combinedAttributes.attributes as Record<
                    string,
                    any
                  >;
                  const feature = layersObj[key][0];
                  const featurePK = guessFeaturePrimaryKey(
                    Object.keys(feature)
                  ) as string;
                  const selectedFeatureId = feature[featurePK];
                  layerManager.setPrimaryHighlightFilter(key, [
                    selectedFeatureId,
                  ]);
                }}
              ></AttributesCard>
            )}
          </Box>
          <ExplorerDisplayLayers {...layerManager} />
        </Basemap>
      )}
    </>
  );
};
